import React, { useState, useEffect } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Server from '../../serverDetails/server.js';
import Logo from './images/logo.png';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

export default function LoginPage() {
  const [sErrorMessage, setErrorMessage] = useState('');
  const [iErrorCode, setErrorCode] = useState();
  const [bSnackBar, setSnackBar] = useState(false);
  const [bLoading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [CID, setCID] = useState('');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const regex = /^https:\/\/www\.(?!qa\.cloudzudio\.com\/$)([a-zA-Z0-9-]+\.(?!qa$)[a-zA-Z0-9-]+|[a-zA-Z0-9-]+)\.cloudzudio\.com\/$/;
  const hostname = window.location.hostname;

  useEffect(() => {
    const fetchDomain = async () => {
      try {
        setLoading(true);
        const response = await Server.get(
          `/launchpad/domain/${window.location.hostname}`
        );
        if (response.data) {
          setCID(response.data.CID);
          setLoading(false);
          // setImageData(response.data.image.data)
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };
    const verifyToken = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await Server.get(`/verifytoken`, {
            headers: {
              Authorization: token
            }
          });
          if (response.data.Message === 'Token is valid') {
            navigate('/HomePage');
          }
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.message);
        }
        localStorage.removeItem('token');
        localStorage.removeItem('CID');
        localStorage.removeItem('USERID');
        localStorage.removeItem('URL');
        localStorage.removeItem('injected');
        navigate('/');
      }
    };
    if (!hostname.includes('dev') || regex.test(hostname)) {
      fetchDomain();
    }
    verifyToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    Server.post(`/launchpad/login`, {
      CID,
      USERID: userId.toUpperCase(),
      PASSWORD: password
    })
      .then((res) => {
        if (res.data.Message !== 'Login successful') {
          setErrorCode(res.data.statusCode);
          setErrorMessage(res.data.Message);
          setSnackBar(true);
          setLoading(false);
        } else {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('CID', res.data.CID);
          localStorage.setItem('USERID', res.data.USERID);
          if (res.data.ADMIN) {
            localStorage.setItem('ADMIN', res.data.ADMIN);
          }
          navigate('/HomePage');
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.data) {
          setErrorCode(error.response.status);
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
        setLoading(false);
        setSnackBar(true);
      });
  };

  const handleForgotPassword = async () => {
    if (CID !== '' && userId !== '') {
      setLoading(true);
      await Server.post(`/sendemail/forgotpassword`, {
        CID,
        USERID: userId.toUpperCase(),
        subject: 'Forgot Password',
        SITE: 'Launchpad',
        link: `https://${window.location.hostname}/ForgotPassword/${CID}`
      })
        .then((res) => {
          setLoading(false);
          if (res.data.message === 'Mail sent Successfully') {
            setErrorCode(res.status);
            setErrorMessage('Check Your Registered Email');
            setSnackBar(true);
          } else {
            setErrorCode(res.status);
            setErrorMessage(res.data.message);
            setSnackBar(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setErrorMessage(err.message);
          setSnackBar(true);
        });
    } else {
      setErrorCode('500');
      setErrorMessage('CID and USERID cannot Be empty');
      setSnackBar(true);
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(false);
  };

  return (
    <div className="login">
      <div class="login-container">
        <img src={Logo} alt="CloudZudio Logo" class="login-logo" />
        <div class="login-form">
          <div className="loginpage-busyindicator">
            {bLoading && (
              <div className="overlay">
                <ScaleLoader
                  color={'#033146'}
                  loading={bLoading}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  height={25}
                  width={6}
                />
              </div>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            {(hostname.includes('dev') || hostname.includes('localhost') || !regex.test(hostname))&& (
              <div class="form-group">
                <label for="CID">CID</label>
                <input
                  type="text"
                  id="CID"
                  name="CID"
                  value={CID}
                  onChange={(e) => setCID(e.target.value)}
                  required
                />
              </div>
            )}
            <div class="form-group">
              <label for="username">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                required
              />
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div class="form-group">
              <input type="submit" value="Login" />
            </div>
            <a href="#" class="forgot-password" onClick={handleForgotPassword}>
              Forgot Password?
            </a>
          </form>
        </div>
      </div>
      <Snackbar
        open={bSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {`${iErrorCode} - ${sErrorMessage}`}
        </Alert>
      </Snackbar>
    </div>
  );
}
